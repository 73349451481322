<template>
  <administration-layout>
    <div class="py-12">
      <div class="text-xl font-bold">
        <h1>Importar / Exportar</h1>
      </div>
      <div class="flex flex-col items-center">
        <template v-if="componentType">
          <component :is="componentType" :show-checklist-config="true" :show-print-config="true" />
        </template>
        <template v-else>
          <upload-client-file-form />
          <upload-product-file-form />
        </template>
      </div>
    </div>
  </administration-layout>
</template>
<script>
import _ from 'lodash';
import { ImportExportType } from '@/constants';

export default {
  name: 'ImportExport',
  components: {
    AdministrationLayout: () => import('@/components/organisms/TheAdministrationApp'),
    UploadClientFileForm: () => import('@/components/forms/UploadClientFileForm'),
    UploadProductFileForm: () => import('@/components/forms/UploadProductFileForm'),
  },
  mounted() {
    if (!_.isNil(this.$route.params.importExportType)) {
      this.importExportType = this.$route.params.importExportType
    }
  },
  computed: {
    componentType() {
      const componentMap = {
        [ImportExportType.Client]: 'UploadClientFileForm',
        [ImportExportType.Product]: 'UploadProductFileForm',
      };

      return componentMap[this.importExportType] || null;
    },
  },
  data() {
    return {
      importExportType: null,
    }
  },
}
</script>